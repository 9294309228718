import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const getImessageAuthPlatformsApi = `${domain}api/v1/dashboard/auth/platforms/`
const imessageAuthPlatformsObjectApi = (id) => `${domain}api/v1/dashboard/auth/platform/${id}/`
const getUserSessionsApi = `${domain}api/v1/dashboard/auth/sessions/`
const imessageAuthUserObjectApi = (id) => `${domain}api/v1/dashboard/auth/sessions/${id}/`
const getPlatformTypesApi = `${domain}api/v1/dashboard/auth/platform-types/`

export const getImessageAuthPlatforms = () => interceptor(axios).get(getImessageAuthPlatformsApi)
export const createImessageAuthPlatforms = (data) => interceptor(axios).post(getImessageAuthPlatformsApi, data)
export const updateImessageAuthPlatforms = (id, data) => interceptor(axios).post(imessageAuthPlatformsObjectApi(id), data)
export const deleteImessageAuthPlatforms = (id) => interceptor(axios).delete(imessageAuthPlatformsObjectApi(id))
export const getUserSessions = (params) => interceptor(axios).get(getUserSessionsApi, { params })
export const deleteImessageAuthUser = (id) => interceptor(axios).delete(imessageAuthUserObjectApi(id))
export const getPlatformTypes = () => interceptor(axios).get(getPlatformTypesApi)
