import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useModal } from '@/application/composables/modal'
import { createImessageAuthPlatforms, updateImessageAuthPlatforms, deleteImessageAuthPlatforms } from '@/application/services/imessage-auth.js'

export const useImessageAuth = (types) => {
  const i18n = useI18n()
  const { openModal } = useModal()

  const editImessagePlatformsElement = ref(null)
  const modalImessagePlatformsWrapper = ref(null)
  const modalEditImessagePlatformsWrapper = ref(null)
  const isFormSending = ref(false)
  const createFormData = ref({})

  const getSelectedFieldName = (givenType) => {
    if (!givenType) {
      return 'bundleId'
    }
    if (givenType === undefined) {
      return 'bundleId'
    }
    return {
      bundle: 'bundleId',
      domain: 'domain',
      product: 'product',
    }[givenType]
  }

  const imessagePlatformsButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('integrations.form.settings.sendButton'),
  }))

  const getImessageEditValues = computed(() => {
    if (!editImessagePlatformsElement.value) {
      return {}
    }
    return {
      session_term: editImessagePlatformsElement.value.session_term,
      expiration_term: editImessagePlatformsElement.value.session_term,
      expiration_term_type: editImessagePlatformsElement.value.session_term_type,
      multi_session: editImessagePlatformsElement.value.multi_session,
      status_callback: editImessagePlatformsElement.value.status_callback,
      status_callback_header: editImessagePlatformsElement.value.status_callback_header,
    }
  })

  const imessagePlatformsEditFormSchema = computed(() => [
    {
      label: i18n.t('imessagePlatforms.sessionTerms'),
      name: 'expiration_term',
      rules: 'required|max_value:999|min_value:1',
      as: 'input',
      type: 'number',
      cols: 'col-6',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('imessagePlatforms.sessionTermsType'),
      name: 'expiration_term_type',
      as: 'select',
      rules: 'required',
      type: 'number',
      cols: 'col-6',
      inputClass: 'form-control',
      children: [
        {
          tag: 'option',
          value: 'hours',
          text: 'hours',
        },
        {
          tag: 'option',
          value: 'days',
          text: 'days',
        },
      ],
    },
    {
      label: i18n.t('imessagePlatforms.allowMultiSelection'),
      name: 'multi_session',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
      description: i18n.t('forms.imessageAuth.descriptions.multi_session'),
    },
    {
      label: i18n.t('imessagePlatforms.callbackUrl'),
      name: 'status_callback',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: `${i18n.t('optional')}. ${i18n.t('forms.imessageAuth.descriptions.status_callback')}`,
      placeholder: 'e.g. https://api.yourdomain.com/endpoint',
    },
    {
      label: i18n.t('imessagePlatforms.callbackHeader'),
      name: 'status_callback_header',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: `${i18n.t('optional')}. ${i18n.t('forms.imessageAuth.descriptions.status_callback_header')}`,
      placeholder: 'e.g. Bearer Rz3aZxYNQKcwgRva',
      isHTMLDescription: true,
    },
  ])

  const imessageDefaultValues = { expiration_term: 1, expiration_term_type: 'hours' }
  const imessagePlatformsCreateFormSchema = computed(() => [
    {
      label: 'Type',
      name: 'type',
      as: 'select',
      rules: 'required',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      value: '',
      children: types.value.map(typeElement => ({
        tag: 'option',
        value: typeElement,
        text: i18n.t(`imessagePlatforms.types.${typeElement}`),
      })),
    },
    {
      label: i18n.t(`imessagePlatforms.${getSelectedFieldName(createFormData.value && createFormData.value.type)}`),
      name: 'value',
      rules: 'required',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: i18n.t(`forms.imessageAuth.descriptions.${getSelectedFieldName(createFormData.value && createFormData.value.type)}`),
    },
    {
      label: i18n.t('imessagePlatforms.sessionTerms'),
      name: 'expiration_term',
      rules: 'required|max_value:999|min_value:1',
      as: 'input',
      type: 'number',
      cols: 'col-6',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('imessagePlatforms.sessionTermsType'),
      name: 'expiration_term_type',
      as: 'select',
      rules: 'required',
      type: 'number',
      cols: 'col-6',
      inputClass: 'form-control',
      children: [
        {
          tag: 'option',
          value: 'hours',
          text: 'hours',
        },
        {
          tag: 'option',
          value: 'days',
          text: 'days',
        },
      ],
    },
    {
      label: i18n.t('imessagePlatforms.allowMultiSelection'),
      name: 'create_multi_session',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      labelClass: 'cr',
      value: true,
      unckeckedValue: false,
      fieldClass: 'checkbox checkbox-primary d-inline',
      description: i18n.t('forms.imessageAuth.descriptions.multi_session'),
    },
    {
      label: i18n.t('imessagePlatforms.callbackUrl'),
      name: 'status_callback',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: `${i18n.t('optional')}. ${i18n.t('forms.imessageAuth.descriptions.status_callback')}`,
      placeholder: 'e.g. https://api.yourdomain.com/endpoint',
    },
    {
      label: i18n.t('imessagePlatforms.callbackHeader'),
      name: 'status_callback_header',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: `${i18n.t('optional')}. ${i18n.t('forms.imessageAuth.descriptions.status_callback_header')}`,
      isHTMLDescription: true,
      placeholder: 'e.g. Bearer Rz3aZxYNQKcwgRva',
    },
  ])

  const openCreateImessagePlatformsFormTrigger = () => {
    modalImessagePlatformsWrapper.value.open()
  }

  const openEditImessagePlatformsFormTrigger = () => {
    modalEditImessagePlatformsWrapper.value.open()
  }

  const closeCreateImessagePlatformsFormTrigger = () => {
    modalImessagePlatformsWrapper.value.close()
  }

  const closeEditImessagePlatformsFormTrigger = () => {
    modalEditImessagePlatformsWrapper.value.close()
  }

  const sendCreateImessagePlatformsFormHandler = (data) => {
    isFormSending.value = true
    const formattedData = { ...data, multi_session: data.create_multi_session }
    delete formattedData.create_multi_session

    createImessageAuthPlatforms(formattedData).then(({ data }) => {
      isFormSending.value = false
      closeCreateImessagePlatformsFormTrigger()
      openModal(i18n.t('success'), i18n.t('successAdded'), () => {
        window.location.reload()
      })
    }).catch(err => {
      const response = err.response
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.imessageAuth.errors.', response))
      isFormSending.value = false
    })
  }

  const sendEditImessagePlatformsFormHandler = (data) => {
    isFormSending.value = true
    updateImessageAuthPlatforms(editImessagePlatformsElement.value.id, { ...data }).then(({ data }) => {
      isFormSending.value = false
      closeEditImessagePlatformsFormTrigger()
      openModal(i18n.t('success'), i18n.t('successEdited'), () => {
        window.location.reload()
      })
    }).catch(err => {
      closeEditImessagePlatformsFormTrigger()
      const response = err.response
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.imessageAuth.errors.', response))
      isFormSending.value = false
    })
  }

  const deleteImessageSettingHandler = (id) => {
    deleteImessageAuthPlatforms(id).then(() => {
      openModal(i18n.t('success'), i18n.t('successRemoved'), () => {
        window.location.reload()
      })
    }).catch(err => {
      const response = err.response
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.imessageAuth.errors.', response))
    })
  }

  const changeImessageSettingsHandler = (name, values, errors) => {
    createFormData.value = values
  }

  return {
    imessagePlatformsCreateFormSchema,
    imessageDefaultValues,
    imessagePlatformsButton,
    modalImessagePlatformsWrapper,
    modalEditImessagePlatformsWrapper,
    isFormSending,
    openCreateImessagePlatformsFormTrigger,
    closeCreateImessagePlatformsFormTrigger,
    sendCreateImessagePlatformsFormHandler,
    deleteImessageSettingHandler,
    openEditImessagePlatformsFormTrigger,
    closeEditImessagePlatformsFormTrigger,
    getImessageEditValues,
    imessagePlatformsEditFormSchema,
    editImessagePlatformsElement,
    sendEditImessagePlatformsFormHandler,
    changeImessageSettingsHandler,
    getSelectedFieldName,
  }
}
