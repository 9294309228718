<template>
  <div class="page-header-title d-flex justify-content-between w-100 m-b-30">
    <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ $t('iMessageAuthPlatforms') }}</h3>
    <button
      class="btn btn-sm btn-primary"
      @click.prevent="openCreateImessagePlatformsFormTrigger"
    >{{ $t('addNewApplication') }}</button>
  </div>

  <modal-wrapper ref="modalEditImessagePlatformsWrapper">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('edit') }}</h5>
          <button
            @click="closeEditImessagePlatformsFormTrigger"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DynamicForm
            :schema="imessagePlatformsEditFormSchema"
            :button-data="imessagePlatformsButton"
            :is-disabled="isFormSending"
            :initial-field-value="getImessageEditValues"
            form-class="justify-content-start"
            @submit="sendEditImessagePlatformsFormHandler"
          >
          </DynamicForm>
        </div>
      </div>
    </div>
  </modal-wrapper>

  <modal-wrapper ref="modalImessagePlatformsWrapper">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('integrations.form.settings.title') }}</h5>
          <button
            @click="closeCreateImessagePlatformsFormTrigger"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DynamicForm
            :schema="imessagePlatformsCreateFormSchema"
            :button-data="imessagePlatformsButton"
            :is-disabled="isFormSending"
            :initial-field-value="imessageDefaultValues"
            form-class="justify-content-start"
            @submit="sendCreateImessagePlatformsFormHandler"
            @changeHandler="changeImessageSettingsHandler"
          >
          </DynamicForm>
        </div>
      </div>
    </div>
  </modal-wrapper>

  <paginated-table-wrapper
    v-if="license"
    :is-show-filters="false"
    :license="license"
    :request="getImessageAuthPlatformsRequest"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table :list="list" @edit="editImessagePlatformHandler" @delete="deleteImessagePlatformHandler"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import { getImessageAuthPlatforms, getPlatformTypes } from '../../services/imessage-auth.js'
import { store } from '@/application/store'
import { useImessageAuth } from '../../composables/imessageAuth.js'
// import { useI18n } from 'vue-i18n'
import DynamicForm from '../forms/DynamicForm.vue'

export default {
  name: 'LookupHistoryPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/IMessagePlatformsTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    DynamicForm,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
  },
  setup() {
    // const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getImessageAuthPlatformsRequest = getImessageAuthPlatforms
    const types = ref([])

    onMounted(() => {
      getPlatformTypes().then(({ data }) => {
        data.types.forEach(element => {
          types.value.push(element)
        })
      }).catch(err => {
        console.log(err)
      })
    })

    const {
      imessagePlatformsCreateFormSchema,
      imessagePlatformsButton,
      modalImessagePlatformsWrapper,
      isFormSending,
      imessageDefaultValues,
      openCreateImessagePlatformsFormTrigger,
      closeCreateImessagePlatformsFormTrigger,
      sendCreateImessagePlatformsFormHandler,
      deleteImessagePlatformHandler,
      getImessageEditValues,
      imessagePlatformsEditFormSchema,
      openEditImessagePlatformsFormTrigger,
      editImessagePlatformsElement,
      modalEditImessagePlatformsWrapper,
      closeEditImessagePlatformsFormTrigger,
      sendEditImessagePlatformsFormHandler,
      changeImessageSettingsHandler,
    } = useImessageAuth(types)

    return {
      imessagePlatformsCreateFormSchema,
      imessagePlatformsButton,
      modalImessagePlatformsWrapper,
      isFormSending,
      imessageDefaultValues,
      openCreateImessagePlatformsFormTrigger,
      closeCreateImessagePlatformsFormTrigger,
      sendCreateImessagePlatformsFormHandler,
      deleteImessagePlatformHandler,
      getImessageEditValues,
      imessagePlatformsEditFormSchema,
      modalEditImessagePlatformsWrapper,
      closeEditImessagePlatformsFormTrigger,
      sendEditImessagePlatformsFormHandler,
      license,
      getImessageAuthPlatformsRequest,
      editImessagePlatformHandler: (data) => {
        editImessagePlatformsElement.value = data
        openEditImessagePlatformsFormTrigger()
      },
      changeImessageSettingsHandler,
    }
  },
}
</script>
